import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import Base from '@webLayouts/Base/Base';
import { PageModularHomesDesignsQuery } from '@generated/GraphqlTypes';
import Designs from '@webPages/ModularHomes/Designs';
import QAllDesigns from '@staticQueries/AllDesigns';

const IndexPage: React.FC<PageProps<PageModularHomesDesignsQuery>> = ({
  data,
}) => {
  const seoTitle =
    data?.modularHomesDesigns?.frontmatter?.seoTitleDesigns ||
    data?.modularHomesDesigns?.frontmatter?.seoTitle ||
    '';
  const seoDescription =
    data?.modularHomesDesigns?.frontmatter?.seoDescription || '';
  const title = data?.modularHomesDesigns?.frontmatter?.title || '';
  const copy: { title: string; body: string }[] = (
    data?.modularHomesDesigns?.frontmatter?.copy || []
  ).map(a => ({ title: a?.title || '', body: a?.body || '' }));

  return (
    <Base theme="light" title={seoTitle} description={seoDescription}>
      <QAllDesigns>
        {designs => (
          <Designs title={title} tab="designs" designs={designs} copy={copy} />
        )}
      </QAllDesigns>
    </Base>
  );
};

export const query = graphql`
  query PageModularHomesDesigns {
    modularHomesDesigns: markdownRemark(
      fields: { collection: { eq: "pages" } }
      frontmatter: { page: { eq: "modular-homes-designs" } }
    ) {
      frontmatter {
        title
        seoTitle
        seoDescription
        seoTitleDesigns
        seoTitleFloorPlans
        copy {
          title
          body
        }
      }
    }
  }
`;

export default IndexPage;
